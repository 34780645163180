import { combineReducers } from "redux";

import language from "./reducers/languageReducer";
import contracts from "./reducers/contractsReducer";
import data from "./reducers/dataReducer";
import ui from "./reducers/uiReducer";
import network from "./reducers/network";
import configuration from "./reducers/configurationReducer";
import account from "./reducers/accountReducer";
import providers from "./reducers/providerReducer";

const reducers = combineReducers({
  providers,
  account,
  configuration,
  network,
  language,
  contracts,
  data,
  ui,
});

export default reducers;

import React from "react";

import { configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import { avalanche, avalancheFuji, mainnet } from "wagmi/chains";

import { modalConnectors } from "@web3modal/ethereum";

import { getRPCProvider } from "../helpers/providers";

import NotificationContainer from "../common/react-notifications/NotificationContainer";
import Router from "./Router";
import Web3Modal from "./modals/web3Modal";

const chains = [avalanche, avalancheFuji, mainnet];

const { provider } = configureChains(chains, [
  publicProvider(),
  jsonRpcProvider({
    rpc: (chain) => ({
      http: getRPCProvider(chain.id),
    }),
  }),
]);

const client = createClient({
  autoConnect: true,
  connectors: modalConnectors({
    projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
    version: "2", // or "2"
    appName: "web3Modal",
    chains,
  }),
  provider,
});

const App = () => {
  return (
    <>
      <WagmiConfig client={client}>
        <>
          <NotificationContainer />
          <Router />
        </>
      </WagmiConfig>
      <Web3Modal client={client} chains={chains} defaultChain={avalanche} />
    </>
  );
};

export default App;

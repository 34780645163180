import { put, select } from "redux-saga/effects";

import { getContract } from "./contracts";
import { getNodeProvider } from "./provider";

export function* fetchDonations({ payload: { fundId } }) {
  yield put({
    type: "SET_LOADING",
    payload: { data: "donations", state: true },
  });

  const provider = yield getNodeProvider();

  const DonationStorageContract = yield getContract(
    "DonationStorage",
    provider
  );

  const filter = DonationStorageContract.filters.Donated(null, null, fundId);

  const events = yield DonationStorageContract.queryFilter(filter, 0);

  const donations = yield Promise.all(
    events.map(async (event) => ({
      ...event.args,
      txHash: event.transactionHash,
      timestamp: (await event.getBlock()).timestamp,
      blockHash: event.blockHash,
      blockNumber: event.blockNumber,
    }))
  );

  yield put({
    type: "FETCH_DONATIONS_SUCCESS",
    payload: { fundId, donations },
  });

  yield put({
    type: "SET_LOADING",
    payload: { data: "donations", state: false },
  });
}

export function* fetchUserDonations(_action) {
  const provider = yield getNodeProvider();
  const currentAccount = yield select((state) => state.account.account);
  const DonationStorageContract = yield getContract("DonationStorage");
  const filter = DonationStorageContract.filters.Donated(currentAccount);

  const events = yield DonationStorageContract.connect(provider).queryFilter(
    filter,
    0
  );

  const donations = yield events
    .map(({ args }) => args)
    .map((element) => ({
      tokenAddress: element.tokenAddress,
      amount: element.value,
      donatedFundId: element.fundId.toNumber(),
    }));

  yield put({
    type: "SET_USER_DONATIONS",
    payload: { donations },
  });
}

import { ethers } from "ethers";
import { all, call, put } from "redux-saga/effects";
import { zip } from "ramda";
import { getProvider, getContract as getWContract } from "@wagmi/core";

import { getCurrentAccount } from "./account";
import { getContract } from "./contracts";
import { fetchERC20 } from "./ERC20";
import { getNodeProvider } from "./provider";
import ABIS from "../abis";

export function* fetchFunds(_action) {
  try {
    yield put({ type: "FETCHING_FUNDS" });
    const provider = yield getNodeProvider();

    const FundManager = yield getContract("FundManager");
    const filter = FundManager.filters.FundCreated();

    const events = yield FundManager.connect(provider).queryFilter(filter, 0);

    const funds = events
      .map(({ args }) => args)
      .map(([id, address, name, focus, description, requestable]) => ({
        id: id.toNumber(),
        address,
        name,
        focus,
        description,
        requestable,
      }));

    // yield all(
    //   funds.map((fund) =>
    //     put({
    //       type: "INSERT_FUND",
    //       payload: {
    //         ...fund,
    //       },
    //     })
    //   )
    // );
    yield all(funds.map((fund) => call(fetchFundDetails, fund)));

    yield put({ type: "FETCHED_FUNDS" });
  } catch (error) {
    console.log(error);
  }
}

export function* fetchFundDetails(fund) {
  try {
    const provider = yield getNodeProvider();
    const FundV1 = yield getWContract({
      address: fund.address,
      abi: ABIS["FUNDV1"].abi,
    });

    const allowedTokens = yield FundV1.connect(provider).getAllowedTokens();
    var tokenAddresses = yield FundV1.connect(provider).getDonatedTokens();
    var tokenBalances = [];

    for (let i = 0; i < tokenAddresses.length; i++) {
      tokenBalances.push(
        yield FundV1.connect(provider).getBalanceForToken(tokenAddresses[i])
      );
    }
    /////////////////////////////////////////////////////////////////////////////
    // no need for balance set here ?
    /////////////////////////////////////////////////////////////////////////////

    tokenBalances = tokenBalances.map((balance) => balance.toString());

    const balances = zip(tokenBalances, tokenAddresses); // remove later

    yield all(
      allowedTokens.map((address) => call(fetchERC20, { payload: { address } }))
    );

    yield put({
      type: "INSERT_FUND",
      payload: {
        ...fund,
        allowedTokens,
        balances,
      },
    });
  } catch (error) {
    console.error(error);
  }
}

export function* checkIfCurrentAccountHasCreatorRole() {
  const provider = yield getProvider();
  const currentAccount = yield getCurrentAccount();
  const FundManager = yield getContract("FundManager", provider);

  const hasCreatorRole = yield FundManager.hasRole(
    ethers.utils.keccak256(ethers.utils.toUtf8Bytes("CREATOR_ROLE")),
    currentAccount.account
  );

  yield put({
    type: "SET_CREATOR_ROLE",
    payload: { hasCreatorRole },
  });
}

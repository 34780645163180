import { put, select } from "redux-saga/effects";
import { listenForEvent } from "../channels/eventListener";

import { getNodeProvider, getProvider } from "../sagas/provider";
import { getContract } from "./contracts";

export function* listenForMint({ payload: { fundId, fundAddress } }) {
  const currentAccount = yield select((state) => state.account.account);
  const provider = yield getProvider();
  const SOS = yield getContract("SOS", provider);
  const nfts = yield select((state) => state.account.nfts);

  const filter = SOS.filters.Transfer(null, currentAccount);

  yield listenForEvent(
    SOS,
    filter,
    (_from, _to, tokenId) => ({
      tokenId,
    }),
    function* (channel, { tokenId }) {
      if (!nfts.find((id) => tokenId.toNumber() === id)) {
        yield put({ type: "INSERT_TOKEN_ID", payload: { tokenId } });
        yield put({ type: "EXIT_TX_STATE" });
        yield put({ type: "MINTED_TOKEN", payload: { tokenId } });
        yield put({
          type: "FETCH_FUND_BALANCES",
          payload: { fundId, fundAddress },
        });
        yield put({
          type: "FETCH_DONATIONS",
          payload: { fundId },
        });
        yield put({
          type: "FETCH_USER_DONATIONS",
        });
        yield channel.close();
      }
    }
  );
}

export function* fetchMints(_action) {
  try {
    const provider = yield getNodeProvider();
    const currentAccount = yield select((state) => state.account.account);
    const SOS = yield getContract("SOS");
    const filter = SOS.filters.Transfer(null, currentAccount);

    const events = yield SOS.connect(provider).queryFilter(filter, 0);

    const allNFTs = yield events
      .map(({ args }) => args)
      .map(({ tokenId }) => tokenId.toNumber());

    yield put({
      type: "SET_NFTS",
      payload: { allNFTs },
    });
  } catch (error) {
    console.error(error);
  }
}

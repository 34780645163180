const INITIAL_STATE = {
  networks:
    process.env.NODE_ENV === "development"
      ? [43113, 43114, 31337]
      : [43113, 43114],
};

const reducer = (state = INITIAL_STATE, { type }) => {
  switch (type) {
    default:
      return state;
  }
};

export default reducer;
